<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>{{ this.$t('api_integration.api_integration') }}</li>
        </ul>
      </nav>
    </div>
    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span>{{ this.$t('api_integration.api_integration') }}</span>
        </h2>
      </div>
    </div>
    <template v-if="errorMessages">
      <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red">
          <i class="uil-exclamation-triangle"></i> {{ error }}
        </p>
      </div>
    </template>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>

    <div v-if="isLoading" class="card">
      <default-loading/>
    </div>

    <div v-else class="chats-container margin-top-0">
      <div class="chats-container-inner">
        <!-- chats -->
        <div class="chats-inbox for_megaphone">
          <div class="chats-headline">
            <div class="input-with-icon">
              <input id="autocomplete-input" v-model="filter.search" :placeholder="$t('general.search')" type="text"/>
              <i class="icon-material-outline-search"></i>
            </div>
          </div>

          <div style="padding:22px 30px">
            <div class="uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s pb-3 uk-grid" uk-grid>

              <div v-for="(integration, integrationsIndex) in integrations">
                <div class="card">
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img class="w-75" src="/media/images/logo-anzera.jpg"/>
                    </div>
                    <h5 class="h6 mt-4 mb-0">{{ integration.name }}</h5>
                    <span class="d-block text-sm text-success mb-3" href="#"><i class="uil-check-circle"></i>
                      {{ integration.activated_at ? 'active' : 'passive' }}</span>
                  </div>
                  <div class="card-footer text-center py-3 d-flex flex-column align-items-center">
                    <label class="uk-switch uk-light">
                      <input v-model="toggleActive[integrationsIndex]" type="checkbox"
                             @change="toggleChange($event.target.checked, integrationsIndex)">
                      <div class="uk-switch-slider"></div>
                    </label>
                    <a class="uk-button uk-button-default uk-button-small" href="#edit-integration" uk-toggle
                       @click="openSettings(integration)"><i class="uil-cog"></i> {{
                        $t('general.settings')
                      }}</a>
                  </div>
                </div>
              </div>
              <!-- <div>
                <div class="card">
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img class="w-75" src="/media/images/logo-kolayik.jpg" />
                    </div>
                    <h5 class="h6 mt-4 mb-0">Kolay İK</h5>
                    <span href="#" class="d-block text-sm text-muted mb-3">Passive</span>
                  </div>
                  <div class="card-footer text-center py-3 d-flex justify-content-between">
                    <label class="uk-switch uk-light" for="kolayik">
                      <input type="checkbox" id="kolayik">
                      <div class="uk-switch-slider"></div>
                    </label>
                    <a disabled href="#edit-integration" uk-toggle class="uk-button uk-button-default uk-button-small"><i
                        class="uil-cog"></i> Ayarlar</a>
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img class="w-75" src="/media/images/logo-sap.jpg" />
                    </div>
                    <h5 class="h6 mt-4 mb-0">SAP</h5>
                    <span href="#" class="d-block text-sm text-danger mb-3"><i class="uil-times-circle"></i> Failed</span>
                  </div>
                  <div class="card-footer text-center py-3 d-flex justify-content-between">
                    <label class="uk-switch uk-light" for="sap">
                      <input type="checkbox" id="sap" checked>
                      <div class="uk-switch-slider"></div>
                    </label>
                    <a href="#edit-integration" uk-toggle class="uk-button uk-button-default uk-button-small"><i
                        class="uil-cog"></i> Ayarlar</a>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- chats / End -->
      </div>
    </div>

    <!--Modal - Edit Integration-->
    <div id="edit-integration" uk-modal>
      <div :class="dynamicWidthClass" class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title"> {{ selectedIntegration }} {{ $t('api_integration.settings') }} </h2>
        </div>
        <div class="uk-modal-body">

          <div class="form-group row">
            <div :class="hasAttributes  ?'col-6 ':'col-12'" class="form-group-wrapper">
              <h3 v-if="hasAttributes" class="uk-heading"> {{ $t('general.basic_areas') }} </h3>
              <div v-for="(field, key) in fields" :key="key">
                <div v-if="key !== 'attributes'" class="uk-margin">
                  <label class="uk-form-label">
                    {{  $t(`api_integration.${key}`) }} <span class="required">*</span>
                  </label>
                  <input v-model="fields[key]" :placeholder=" $t(`api_integration.${key}_ph`)" class="uk-input" type="text"/>
                </div>
              </div>
            </div>
            <div v-if="hasAttributes" class="col-6 form-group-wrapper">
              <h3 class="uk-heading"> {{ $t('general.attributes_areas') }}</h3>
              <div class="uk-margin">
                <div v-for="(attribute, attributeKey) in fields.attributes" :key='`attr-${attributeKey}`'>
                  <label class="uk-form-label">
                    {{  $t(`api_integration.${attributeKey}`) }} <span class="required">*</span>
                  </label>
                  <input :key="`input-${attributeKey}`" v-model="fields.attributes[attributeKey]"
                         :placeholder=" $t(`api_integration.${attributeKey}_ph`)" class="uk-input" type="text"/>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="uk-modal-footer">
          <div class="uk-margin uk-flex uk-flex-between">
            <button class="uk-button uk-button-default uk-modal-close uk-width-1-3" type="button">
              {{ $t("general.cancel") }}
            </button>
            <button class="uk-button uk-button-primary uk-modal-close uk-width-1-3 custom-save-button" type="button"
                    @click="saveForm">
              {{ $t("general.save") }}
              <i class="uil-arrow-right" size="24"></i>
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import store from "@/core/services";

import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  GET_ITEMS,
  CREATE_ITEM,
  UPDATE_ITEM,
} from "@/core/services/store/integrations.module";
import DefaultLoading from "@/view/components/loading/DefaultLoading.vue";

export default {
  name: "IntegrationsList",
  components: {DefaultLoading},
  data() {
    return {
      toggleActive: [],
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        sort: "id",
        order: "desc",
      },
      integrations: [],
      selectedIntegration: '',
      fields: {},
      integrationId: '',
      isLoading: false,

    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    dynamicWidthClass() {
      return this.fields?.attributes && Object.keys(this.fields.attributes).length > 0 ? 'modal-w-900' : 'modal-w-600'
    },
    hasAttributes() {
      return this.fields?.attributes && Object.keys(this.fields.attributes).length > 0
    }
  },

  methods: {
    toggleChange(event, index) {
      this.integrationId = this.integrations[index].id;
      let sendData = {
        "is_active": event ? true : false
      };
      event ? this.integrations[index].activated_at = true : this.integrations[index].activated_at = false;
      store.dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
        url: 'api/company/' + this.integrationId + '/integrations',
        contents: sendData,
      }).then((response) => {
        if (!this.error) {
          this.successMessage = this.$t(response);
          window.location.reload();
        } else this.errorMessages.push(this.error);
      });

      this.toggleActive[index] = event
    },
    openSettings(event) {
      console.log("openSettings",event)
      this.fields = {...event.fields, attributes: {...event.fields?.attributes}};
      this.selectedIntegration = event.name
      this.integrationId = event.id;
    },
    saveForm() {

      store.dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
        url: 'api/company/' + this.integrationId + '/integrations',
        contents: this.fields,
      }).then((response) => {
        if (!this.error) {
          this.successMessage = this.$t("general.successfully_created");
          // there ll be 2 payment methods, only one method can be activated nd this ll be controlled by back-end, so need to reload whole page
          // TODO after second payment method applied, reload can applied only for those both payment methods
          window.location.reload();

        } else {
          this.errorMessages.push(this.error);
        }

      }).catch((err) => {
        this.errorMessages.push(err);
      });

      UIkit.modal("#edit-integration").hide();

    },
    getResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        // filters: {
        //   like: self.filter.search,
        //   page: self.page,
        //   per_page: self.per_page,
        //   sort: self.filter.sort,
        //   order: self.filter.order,
        // },
      }).then((response) => {
            this.integrations = response;
            this.integrations.forEach((integration, index) => {
              if (integration.activated_at) {
                this.toggleActive[index] = true;
              } else {
                this.toggleActive[index] = false;
              }
            })
            this.isLoading = false
          }
      );
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.isLoading = true
    this.getResult();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>


<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-switch {
  height: 24px;
  width: 50px;
}

.uk-switch-slider:before {
  width: 20px;
  height: 20px;
}

.custom-save-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3E416D !important;
  color: white !important;
  border: none;

  i {
    color: white !important;
    font-size: 24px;
  }
}

.custom-save-button:hover {
  background-color: #0056ae !important; /* Daha koyu bir hover efekti */
}

.modal-w-600 {
  width: 600px;
}

.modal-w-900 {
  width: 900px;
}

.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: #e6e6e6;
}
</style>

